@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    outline: none;
    /* border: 1px solid red; */
    scroll-behavior: smooth;
    user-select: none;
}

.page-width {
    width: 100%;
    height: 100dvh;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content {
    width: 450px;
    height: 350px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 16px;
    border: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    position: relative;
    bottom: 100px;
}

.formulaire-recherche {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 90%;
}

.formulaire-recherche input {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    padding: 0px 10px;
    font-size: 1em;
    font-family: "Poppins", sans-serif;
    border: 2px solid #EEEEEE;
}
.formulaire-recherche input:focus{
    border: 2px solid #B80000;
}
.formulaire-recherche p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: left;
}

.formulaire-bouton {
    height: 40px;
    width: 50%;
    border-radius: 12px;
    background-color: #B80000;
    color: #fff;
    border: none;
}

.formulaire-bouton:hover {
    background-color: #df0303;
    cursor: pointer;
}

.formulaire-nom,
.formulaire-prenom {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    font-family: "Poppins", sans-serif;
    font-size: 1.1em;
}

.partie-recherche {
    width: 450px;
}
.partie-recherche p{
    color: red;
    font-family: "Poppins", sans-serif;
}

.resultat-recherche {
    background-color: white;
    width: 100%;
    min-height: 50px;
    height: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 0px 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    gap: 10px;
}

.bx-check-circle{
    color: #4ECB71;
    font-size: 2rem;
}
.bx-x-circle{
    color: #B80000;
    font-size: 2rem;
}

@media (max-width: 500px) {
    .partie-recherche {
        width: 400px;
    }
    .partie-recherche h2{
        margin-bottom: 10px;
    }
    .content {
        width: 400px;
        height: 400px;
    }
    .resultat-recherche{
        padding: 0px 8px;
        justify-content: flex-end;
    }
}