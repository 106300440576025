@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    outline: none;
    /* border: 1px solid red; */
    scroll-behavior: smooth;
    user-select: none;
}

:root {
    --rouge: #E12734;
    --blanc: #FFF;
}


@media screen and (min-width: 320px) {
    .back-test {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(184, 0, 0);
        background: linear-gradient(167deg, rgba(184, 0, 0, 1) 0%, rgba(48, 14, 14, 1) 48%, rgba(6, 4, 7, 1) 100%);
        background-size: cover;
        background-position: center;
    }


    .wrapper {
        width: 420px;
        color: #fff;
        border-radius: 10px;
        padding: 30px 40px;
        position: relative;
        bottom: 80px;
    }

    .wrapper {
        font-size: 24px;
        text-align: center;
    }

    .wrapper .input-box {
        position: relative;
        width: 100%;
        height: 50px;
        margin: 30px 0;
    }

    .input-box input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border: 2px solid rgba(255, 255, 255, .2);
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        padding: 20px 45px 20px 20px;
    }

    .input-box input::placeholder {
        color: #fff;
    }

    .input-box i {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .wrapper .remember-forgot {
        display: flex;
        justify-content: space-between;
        font-size: 14.5px;
        margin: -15px 0 15px;
    }

    .remember-forgot label input {
        accent-color: #fff;
        margin-right: 3px;
    }

    .remember-forgot a {
        color: #fff;
        text-decoration: none;
    }

    .remember-forgot a:hover {
        text-decoration: underline;
    }

    .wrapper .btn {
        width: 100%;
        height: 45px;
        background: #fff;
        border: none;
        outline: none;
        border-radius: 40px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        cursor: pointer;
        font-size: 1.1rem;
        color: #333;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        
    }

    .wrapper .btn:hover {
        background: #d2d2d2;
        transition: 0.1s ease-in-out;
    }

    .input-box input:focus {
        border: 2px solid #fff;
    }

    .remember-forgot-coche {
        display: flex;
        justify-content: center;
        gap: 6px;
    }

    .remember-forgot-coche label,
    .remember-forgot-coche input,
    .remember-forgot a {
        cursor: pointer;
    }

    .mdpoublie {
        font-size: 1.8rem;
        text-transform: uppercase;
        text-align: center;
    }
}

@media screen and (min-width: 1280px) {
    .back-test {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
    }


    .wrapper {
        width: 420px;
        color: #fff;
        border-radius: 10px;
        padding: 30px 40px;
    }

    .wrapper {
        font-size: 24px;
        text-align: center;
    }

    .wrapper .input-box {
        position: relative;
        width: 100%;
        height: 50px;
        margin: 30px 0;
    }

    .input-box input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border: 2px solid rgba(255, 255, 255, .2);
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        padding: 20px 45px 20px 20px;
    }

    .input-box input::placeholder {
        color: #fff;
    }

    .input-box i {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .wrapper .remember-forgot {
        display: flex;
        justify-content: space-between;
        font-size: 14.5px;
        margin: -15px 0 15px;
    }

    .remember-forgot label input {
        accent-color: #fff;
        margin-right: 3px;
    }

    .remember-forgot a {
        color: #fff;
        text-decoration: none;
    }

    .remember-forgot a:hover {
        text-decoration: underline;
    }

    .wrapper .btn {
        width: 100%;
        height: 45px;
        background: #fff;
        border: none;
        outline: none;
        border-radius: 40px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        cursor: pointer;
        font-size: 16px;
        color: #333;
        font-weight: 600;
    }

    .wrapper .btn:hover {
        background: #d2d2d2;
    }

    .input-box input:focus {
        border: 2px solid #fff;
    }

    .remember-forgot-coche {
        display: flex;
        justify-content: center;
        gap: 6px;
    }

    .mdpoublie {
        font-size: 1.8rem;
        text-transform: uppercase;
        text-align: center;
    }

    .Back-button {
        color: var(--blanc);
        position: relative;
        right: 10px;
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }


    .linkretour i {
        color: var(--blanc);
        font-size: 1.8rem;
    }

    .linkretour i:hover {
        color: #d2d2d2;
    }
}

.wrapper {
    width: 420px;
    border: 2px solid rgba(255, 255, 255, 0.527);
    background-color: rgba(173, 170, 170, 0.25);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
}